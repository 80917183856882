@import url(https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;1,300&family=Raleway:wght@100;200;500&display=swap);
/*********************************
GLOBAL
*********************************/

/*
font-family: 'Merriweather', serif;
font-family: 'Raleway', sans-serif;
*/

* {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
}

html,
body {
  margin: 0;
  padding: 0;
  font-family: 'Open Sans', serif;
  background: #FBFBFB;
  color: #474747;
  margin-left:18%;
  margin-right:18%;
}

/***
MARKDOWN TEMPLATE RULES
***/ 


/* 
  ##Device = Desktops
  ##Screen = 1281px to higher resolution desktops
*/

@media (min-width: 1281px) {
  
  /* CSS */
/* 
  ##Device = Laptops, Desktops
  ##Screen = B/w 1025px to 1280px
*/
}
@media (min-width: 1025px) and (max-width: 1280px) {
  
  /* CSS */

  html,
  body {
      margin-left:20px;
      margin-right:20px;
  }

}

/* 
  ##Device = Tablets, Ipads (portrait)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) {
  
  /* CSS */
  html,
  body {
      margin-left:20px;
      margin-right:20px;

  }

  
}

/* 
  ##Device = Tablets, Ipads (landscape)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  
  /* CSS */
  html,
  body {
      margin-left:20px;
      margin-right:20px;
  }
  
}

/* 
  ##Device = Low Resolution Tablets, Mobiles (Landscape)
  ##Screen = B/w 481px to 767px
*/

@media (min-width: 481px) and (max-width: 767px) {
  
  /* CSS */
  html,
  body {
      margin-left:10px;
      margin-right:10px;
  }
  
}

/* 
  ##Device = Most of the Smartphones Mobiles (Portrait)
  ##Screen = B/w 320px to 479px
*/

@media (min-width: 320px) and (max-width: 480px) {
  
  /* CSS */
  html,
  body {
      margin-left:10px;
      margin-right:10px;
  }
  
}

